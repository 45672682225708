import React, { useEffect, useState, useContext, useRef } from 'react';
import dolbyAtmosphere from '@assets/pictures/dolby_atmosphere.png';
import mx4dPicture from '@assets/pictures/mx4d.png';
import christie from '@assets/pictures/svgs/christie.svg';
import laserTcmPicture from '@assets/pictures/laser_tcm.png';
import fabTcmPicture from '@assets/pictures/fab_tcm.png';
import omegaTcmPicture from '@assets/pictures/omega_tcm.png';
import pepLogo from '@assets/pictures/pep_logo.png';
import facebookIcon from '@assets/pictures/icons8-facebook-24.png';
import instagramIcon from '@assets/pictures/icons8-instagram-48.png';
import youtubeIcon from '@assets/pictures/icons8-youtube-50.png';
import appleStoreIcon from '@assets/pictures/svgs/applestore_tcm.svg';
import googleStoreIcon from '@assets/pictures/svgs/googlestore_tcm.svg';
import useIsMobile from '../../customHooks/useIsMobile';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, Link } from 'react-router-dom';
import useIsTablet from '@src/customHooks/useIsTablet.js';
import { ReactComponent as HomeIcon } from '@assets/pictures/svgs/home_new.svg';
import { ReactComponent as TicketIcon } from '@assets/pictures/svgs/ticket_new.svg';
import { ReactComponent as ProfileIcon } from '@assets/pictures/svgs/profile__new.svg';
import { setShowLoginPopup } from '@store/auth/authActions';
import GenericService from '@apiService/GenericService';
import { fetchAllCinemas, selectedCinema } from '@store/cinemas/cinemasActions';
import facebook from '@assets/pictures/icons8-facebook.svg';
import facebook1 from '@assets/pictures/icons8-facebook1.svg';
import instagram from '@assets/pictures/icons8-instagram.svg';
// import instagram1 from '@assets/pictures/icons8-instagram1.svg';
import instagram1 from '@assets/pictures/icons8-instagram-50.svg';
import youtube from '@assets/pictures/icons8-youtube.svg';
import youtube1 from '@assets/pictures/icons8-youtube1.svg';
import linkdin from '@assets/pictures/linkedin.svg';
import linkdin1 from '@assets/pictures/linkedin1.svg';
import  Tiktok  from '@assets/pictures/icons8-tiktok-250.svg';
import  Tiktok1  from '@assets/pictures/icons8-tiktok-240.svg';

const Footer = ({ t }) => {
  const dispatch = useDispatch();

  const loginDetails = useSelector((state) => state.auth);
  const { loginError, loggedInUser, guestUser } = loginDetails;
  const isMobile = useIsMobile();
  const history = useHistory();
  const newsLetterEmail = useRef();
  const isTablet = useIsTablet();
  const subscribeMsg = useRef();
  const organization = useSelector((state) => state.movies.organizationDetails);
  const [isHovered, setIsHovered] = useState(false);

  const social_media = useSelector((state) => state.homepage.social_media_cms);
  const footer_cms = useSelector((state) => state.homepage.footer_cms);
  const CMSLOADER = useSelector((state) => state.homepage.cmsLoader);
  const [showBottomMenu, setShowBottomMenu] = useState(true);
  const cinemas = useSelector((state) => state.cinemas);
  const { cinemasLoading, allCinemas } = cinemas;
  

  const {
    org_logo_url,
    org_mycinema_logo,
    org_name,
    org_address,
    org_emailid,
    org_contact_no,
    org_contact_no_code,
  } = organization;

  const { pathname } = useLocation();

  const handleLoginPopup = (toShow) => {
    dispatch(setShowLoginPopup(toShow));
  };

  useEffect(() => {
    // console.log("social_media", social_media);
  }, [social_media]);

  useEffect(() => {}, [pathname]);

  const currentTab = (path) => {
    let tab = '';
    if (path.startsWith('/home') || path.startsWith('/')) tab = 'homeTab';
    if (path.startsWith('/schedules')) tab = 'scheduleTab';
    if (path.startsWith('/guest-user-profile') || path.startsWith('/user-profile'))
      tab = 'userTab';

    return tab;
  };

  useEffect(() => {
    if (!allCinemas?.length) {
      dispatch(fetchAllCinemas());
    }
  }, [dispatch, allCinemas]);

  async function subscribe() {
    const payload = {
      text: newsLetterEmail.current.value,
    };
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        payload.text,
      )
    ) {
      const response = await GenericService.GetNewsLetter(payload);
      if (response.status === 200 && response.data.status === true) {
        subscribeMsg.current.innerText = t('Thank you for subscribing');
        subscribeMsg.current.className = 'text-success';
      } else {
        subscribeMsg.current.innerText = t('You have already subscribed!');
        subscribeMsg.current.className = 'text-warning';
      }
      console.log(response);
    } else {
      subscribeMsg.current.innerText = t('Please enter a valid email');
      subscribeMsg.current.className = 'text-danger';
    }
  }

  const showItem = (item) => {
    if (!CMSLOADER) {
      if (footer_cms && footer_cms.length > 0) {
        if (footer_cms[0][item] == 'Y') {
          return true;
        } else if (footer_cms[0][item] == 'N') {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const isItemActive = (item) => {
    if (!CMSLOADER) {
      if (social_media && social_media.length > 0) {
        if (social_media[0][item]) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const openUrl = (url) => {
    console.log('i am here', url);
    const newWindow = window.open(url, '_blank');
    if (newWindow) newWindow.opener = null;
  };

  if (
    pathname.startsWith('/schedules') ||
    pathname.startsWith('/fnb') ||
    pathname.startsWith('/create-show') ||
    pathname.startsWith('/screening-type') ||
    pathname.startsWith('/seat-layout') ||
    pathname.startsWith('/payment') ||
    pathname.includes('booking') ||
    pathname.startsWith('/final-ticket') ||
    pathname.startsWith('/unpaid-ticket') ||
    pathname.startsWith('/gift-card-details') ||
    pathname.startsWith('/gift-card-payment') ||
    pathname.startsWith('/gift-card-topup')

    // ||
    // (pathname.startsWith("/movie-details") &&
    //   pathname.split("/").length >= 5 &&
    //   pathname.split("/")[4] !== "2")
  ) {
    return null;
  }
  let current_pathname = window.location.href.split('/');

  let pathCheck = current_pathname[3];

  // className=" text-light  py-5"
  return (
    <footer className="footer_new">
      {isMobile ? (
        // Mobile Design
        <>
          {showBottomMenu && (
            <div className="container-fluid">
              <section className="row">
                <article className="col-12">
                  <div className="footer_new_icon_links">
                    <HomeIcon
                      className={currentTab(pathname) === 'homeTab' ? 'active' : ''}
                      onClick={() => {
                        history.push('/');
                      }}
                    />
                    <TicketIcon
                      className={
                        currentTab(pathname) === 'scheduleTab' ? 'active' : ''
                      }
                      onClick={() => {
                        history.push('/schedules');
                      }}
                    />
                    <ProfileIcon
                      className={currentTab(pathname) === 'userTab' ? 'active' : ''}
                      onClick={() => {
                        if (!loggedInUser && guestUser) {
                          history.push('/guest-user-profile');
                        } else if (loggedInUser) {
                          history.push('/user-profile');
                        } else {
                          handleLoginPopup(true);
                        }
                      }}
                    />
                  </div>
                </article>
              </section>
            </div>
          )}
        </>
      ) : (
        // Desktop/Tablet Design
        <>
          <div className=" text-light  py-5"       style={{ backgroundColor: '#1A1A1A', padding: '0 60px' }}>
            <div className="row justify-content-center">
              {/* Column 1 - Our Family */}
              <div className="col-12 col-md-3 mb-4">
                <h5 className="text-uppercase footer_font-size_hed mb-3">Our Family</h5>
                <ul className="list-unstyled footer_font-size">
                <li className="mb-3  footer_font-size">Cinerji Morocco Mall</li>
                  <li className="mb-3">Cinerji City Center</li>
                  <li className='mb-3'>Cinerji Ville Verte</li>
                  <li className="mb-3">Cinerji Kenitra</li>
                  <li className="mb-3">Cinerji Carousel</li>
                  
                </ul>
              </div>

              {/* Column 2 - About Us */}
              <div className="col-12 col-md-3 mb-4">
                <h5 className="text-uppercase mb-3 footer_font-size_hed">About Us</h5>
                <ul className="list-unstyled footer_font-size about_us_ls">
                  <li className="mb-3">
                    <Link
                      to="/schedules"
                      className="text-decoration-none text-white link-hover"
                    >
                      Showtimes
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link
                      to="/experience"
                      className="text-decoration-none text-white link-hover"
                    >
                      Experiences
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link
                      to="/foodnbeverages"
                      className="text-decoration-none text-white link-hover"
                    >
                      Food
                    </Link>
                  </li>

                  {false &&(<li className="mb-3">
                    <Link
                      to="/Locations"
                      className="text-decoration-none text-white link-hover"
                    >
                      Locations
                    </Link>
                  </li>)}
                  {false &&(<li className="mb-3">
                    <Link to="/contact-us" className="text-decoration-none text-white link-hover">
                      Contact us
                    </Link>
                  </li>)}
                  <li className="mb-3">
                    <Link to="/" className="text-decoration-none text-white link-hover">
                      About us
                    </Link>
                  </li>
                </ul>
              </div>

              {/* Column 3 - Locations */}
             {false &&( <div className="col-12 col-md-3 mb-4">
                <h5 className="text-uppercase mb-3 footer_font-size_hed">Locations</h5>
                {/* <ul className="list-unstyled footer_font-size">
                <li className="mb-3  footer_font-size">Cinerji Morocco Mall</li>
                  <li className="mb-3">Cinerji City Center</li>
                  <li className="mb-3">Cinerji Kenitra</li>
                  <li className="mb-3">Cinerji Carousel</li>
                </ul> */}
                <ul className="list-unstyled footer_font-size about_us_ls">
                {allCinemas?.map((cinema) => (
                  <li className="mb-3" key={cinema.cinema_id}>
                    <Link
                      to={`/Locations/${cinema.cinema_id}`}
                      className="text-decoration-none text-white link-hover"
                    >
                      {cinema.cinema_name}
                    </Link>
                  </li>
                ))}
              </ul>

              </div>)}

              <div className="col-12 col-md-3 mb-4">
                <h5 className="text-uppercase mb-3 footer_font-size_hed">Follow Us</h5>
                <div className="d-flex gap-3">
                  {/* <a href="#" className="text-light">
                    <img src={facebookIcon} alt="" />
                    <i className="fab fa-facebook-f"></i>
                  </a>

                  <a href="#" className="text-light ml-3">
                    <img src={instagramIcon} alt="" height="30" width="30" />
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a href="#" className="text-light ml-3">
                    <img src={youtubeIcon} alt="" height="30" width="30" />
                    <i className="fab fa-youtube"></i>
                  </a> */}
                  <div className="instagram-container">
                        <a
                          href="#"
                          target="_blank"
                          rel="noopener noreferrer"
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        >
                          <img
                            src={facebook}
                            alt="facebook"
                            className="instagram-image"
                          />
                          {isHovered && (
                            <img
                              src={facebook1}
                              alt="facebook"
                              className="hover-image"
                            />
                          )}
                        </a>
                      </div>
                      <div className="instagram-container" style={{marginLeft:'10px'}}>
                        <a
                          href="https://instagram.com/cinerjicinemas"
                          target="_blank"
                          rel="noopener noreferrer"
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        >
                          <img
                            src={instagram1}
                            alt="instagram"
                            className="instagram-image"
                          />
                          {isHovered && (
                            <img
                              src={instagram}
                              alt="instagram"
                              className="hover-image"
                            />
                          )}
                        </a>
                      </div>
                      <div className="instagram-container" style={{marginLeft:'10px'}}>
                        <a
                          href="#"
                          target="_blank"
                          rel="noopener noreferrer"
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        >
                          <img
                            src={youtube}
                            alt="youtube"
                            className="instagram-image"
                          />
                          {isHovered && (
                            <img
                              src={youtube1}
                              alt="youtube"
                              className="hover-image"
                            />
                          )}
                        </a>
                      </div>
                      <div className="instagram-container" style={{marginLeft:'10px'}}>
                        <a
                          href="https://www.linkedin.com/company/cinerji-cinemas"
                          target="_blank"
                          rel="noopener noreferrer"
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        >
                          <img
                            src={linkdin}
                            alt="linkdin"
                            className="instagram-image"
                          />
                          {isHovered && (
                            <img
                              src={linkdin1}
                              alt="linkdin"
                              className="hover-image"
                            />
                          )}
                        </a>
                      </div>
                      <div className="instagram-container" style={{marginLeft:'10px'}}>
                        <a
                          href="#"
                          target="_blank"
                          rel="noopener noreferrer"
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        >
                          <img
                            src={Tiktok}
                            alt="tiktok"
                            className="instagram-image"
                          />
                          {isHovered && (
                            <img
                              src={Tiktok1}
                              alt="tiktok"
                              className="hover-image"
                            />
                          )}
                        </a>
                      </div>
                </div>
              </div>
            </div>
            <hr style={{ borderColor: "gray" }} />
            {/* Partners and Technology */}
            <div className="row mt-3 d-flex justify-content-between align-items-center">
              {/* Technology Partners */}
              <div className="col-12 col-md-4 mb-4">
                {!isTablet &&(<div>
                  <h5 className="text-uppercase text-left w-75 footer_font-size">
                    Technology Partners
                  </h5>
                  <div className="d-flex justify-content-start align-items-center w-75 flex-wrap gap-10 mt-3">
                    <img
                      src={dolbyAtmosphere}
                      alt="Dolby Atmos"
                      width="80"
                      height="25"
                    />
                    <img src={christie} alt="MX4D" width="80" height="35" className="ml-4" />
                  </div>
                </div>
                )}

                {isTablet &&(<div>
                  <h5 className="text-uppercase text-left w-100 footer_font-size">
                    Technology Partners
                  </h5>
                  <div className="d-flex justify-content-between align-items-center w-100 flex-wrap gap-10 mt-3">
                    <img
                      src={dolbyAtmosphere}
                      alt="Dolby Atmos"
                      width="80"
                      height="25"
                    />
                    <img src={christie} alt="MX4D" width="80" height="35"/>
                  </div>
                </div>
                )}
              </div>

              <div className="col-12 col-md-4 mb-4">
                {isTablet ? (
                  <div>
                    <div className="d-flex ">
                      <div className="cursor-pointer">
                        <img src={appleStoreIcon} alt="" height="60" width="90" />
                      </div>
                      <div
                        className="cursor-pointer"
                        style={{ marginLeft: '8px' }}
                      >
                        <img src={googleStoreIcon} alt="" height="60" width="90" />
                      </div>
                    </div>
                    {false && (<div className="d-flex ">
                      <div>
                        <figure className="footer_new_logo">
                          {showItem('Logo') ? (
                            <Link className="mb-0" to="/">
                              <img
                                src={org_mycinema_logo || org_logo_url}
                                alt={org_name}
                                // width="115px"
                                width="200px"
                                className=""
                              />
                            </Link>
                          ) : (
                            <div
                              className="dummy-image"
                              style={{
                                width: '100px',
                              }}
                            ></div>
                          )}
                        </figure>
                      </div>
                    </div>
                    )}
                    <div className="m-1">
                        <div>
                          <p className="footer_font-size_tmtb">
                            &copy; 2024 Cinerji Cinema Morocco
                          </p>
                        </div>
                        <div className="d-flex align-items-center">
                          <Link to="/tnc" className="text-light footer_font-size_tmtb me-3">
                            Terms & Conditions
                          </Link>{' '}
                          <span className="ml-1">|</span>
                          <Link to="/privacy-policy" className="text-light ml-1 footer_font-size_tmtb">
                            Privacy Policy
                          </Link>
                        </div>
                      </div>
                  </div>
                ) : (
                  <div style={{ paddingLeft: '10px' }}>
                    <div className="d-flex ">
                      <div className="cursor-pointer">
                        <img src={appleStoreIcon} alt="" height="60" width="120" />
                      </div>
                      <div
                        className="cursor-pointer"
                        style={{ marginLeft: '80px' }}
                      >
                        <img src={googleStoreIcon} alt="" height="60" width="120" />
                      </div>
                    </div>
                    <div className="d-flex ">
                    {false && (<div>
                        <figure className="footer_new_logo">
                          {showItem('Logo') ? (
                            <Link className="mb-0" to="/">
                              <img
                                src={org_mycinema_logo || org_logo_url}
                                alt={org_name}
                                // width="115px"
                                width="200px"
                                className=""
                              />
                            </Link>
                          ) : (
                            <div
                              className="dummy-image"
                              style={{
                                width: '100px',
                              }}
                            ></div>
                          )}
                        </figure>{' '}
                      </div> )}
                      <div className="m-1">
                        <div>
                          <p className="footer_font-size_tm">
                            &copy; 2024 Cinerji Cinema Morocco
                          </p>
                        </div>
                        <div className="d-flex align-items-center">
                          <Link to="/tnc" className="text-light footer_font-size_tm me-3">
                            Terms & Conditions
                          </Link>{' '}
                          <span className="ml-1">|</span>
                          <Link to="/privacy-policy" className="text-light ml-1 footer_font-size_tm">
                            Privacy Policy
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* {isTablet &&(<hr style={{ borderColor: "gray" }} />)}
            {isTablet &&(
              <div className="d-flex justify-content-between align-items-center">
              <div>
                <p className="footer_font-size_tm">
                  &copy; 2024 Cinerji Cinema Morocco
                </p>
              </div>
              <div className="d-flex">
                <Link to="/tnc" className="text-light footer_font-size_tm me-3">
                  Terms & Conditions
                </Link>{' '}
                <span className='ml-1'>|</span>
                <Link to="/privacy-policy" className="text-light footer_font-size_tm ml-1">
                  Privacy Policy
                </Link>
              </div>
            </div>
            )} */}
          </div>
        </>
      )}
    </footer>
  );
};

export default Footer;
