// import React from 'react';
import { withNamespaces } from 'react-i18next';

import { Row, Container, Col, Dropdown, Modal, Alert } from 'react-bootstrap';
// import YouTubeImage from '@assets/pictures/youtube_image.png';
import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
// import useIsMobile from '../../customHooks/useIsMobile';
import { useHistory, useLocation } from 'react-router-dom';
import useIsMobile from '@src/customHooks/useIsMobile';
import moment from 'moment';
import { getLangSpecificAttribute } from '@helper/languages';
import { useSelector, useDispatch } from 'react-redux';

// Components

import Slider from 'react-slick';
import seatsImg from '@assets/pictures/dolby_main.jpeg';
// import seatsImg1 from '@assets/pictures/untitled-22.jpg';
import seatsImg1 from '@assets/pictures/platinum suites2.jpeg';
import seatsImg2 from '@assets/pictures/untitled-41.jpg';
import platsuit from '@assets/pictures/CINERJI-2.jpg';
import DolbyExpseat from '@assets/pictures/F03oh0XXwAApJxr.jpg';
import cinerji_cinema from '@assets/pictures/Cinerji-cinema.png';
import imaximg from '@assets/pictures/Imax-Morocco-Mall.jpg.webp';
// cinerji img

import viphall from '@assets/pictures/cinerjiCinemas/3DCinerjiVIPHALL.jpg';
import boutiqhall1 from '@assets/pictures/cinerjiCinemas/Boutique Hall 1.jpg';
import platiniumhall from '@assets/pictures/cinerjiCinemas/Platinium Hall.jpg';
import privatecinema from '@assets/pictures/cinerjiCinemas/Private cinema.png';
import sportroom from '@assets/pictures/cinerjiCinemas/sportroom.png';

// Components
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';

import TrailerIcon from '@assets/pictures/svgs/play_new_exp.svg';
import ModalVideo from 'react-modal-video';
import dummyPosterStatic from '@assets/pictures/dummyPoster.png';

const Experience = ({ t }) => {
  const { t1, banners, isLoading, showOnly = null } = t;
  const isMobile = useIsMobile();
  const history = useHistory();
  const selected_language = useSelector((state) => state.movies.selected_language);
  const { iso_2, lang_id, lang_name } = selected_language;
  const { pathname } = useLocation();
  const isAdvance = useSelector((state) => state.movies.isAdvance);
  const [isOpen, setOpen] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState(null);
  const [videoChannel, setVideoChannel] = useState(null);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="align_new">
        <div className="left_right_align">
          <CenteredTitle
            firstHalf={`${'Experience'}`}
            secondHalf={''}
          />
        </div>
        <section className="container-fluid opening_hours">
          <Row>
            <Col xl={4} sm={12} md={4}>
              <div className="d-block parent_poster_wrapper">
                
                <p className="question_title">VIP Hall</p>
                <div
                  className={`${'movie_card_new custom_border_radius'} `}
                >
                 

                  <figure className="experience_img">
                    <img
                      src={viphall}
                      className="img-fluid "
                      alt={t('alt.Movie Poster')}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = dummyPosterStatic;
                      }}
                    />
                    
                  </figure>
                  <div className="experince_poster_details custon_z_index">
                    <div
                      className={`d-flex justify-content-between ${
                        isMobile ? 'mb-2' : ''
                      }`}
                    >
                      <div className="responsive_font_movie_title">
                      Experience premium cinema in our VIP Hall, combining exceptional comfort and a refined ambiance. Enjoy spacious seating and personalized service for a truly immersive movie experience. Perfect for those who appreciate luxury and conviviality.{' '}
                      </div>
                    </div>
                    <div className="explore_wrapper mt-2">
                      <button
                        className="explore_btn"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          history.push('/VipExp');
                        }}
                      >
                        Explore
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <Col xl={4} sm={12} md={4}>
              <div
                className="d-block parent_poster_wrapper"
                style={{ marginTop: isMobile ? '22px' : '' }}
              >
                <p className="question_title">Platinum Hall</p>
                <div
                  className={`${'movie_card_new custom_border_radius'} `}
                >
                  <figure className="experience_img">
                    <img
                      src={platiniumhall}
                      className="img-fluid"
                      alt={t('alt.Movie Poster')}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = dummyPosterStatic;
                      }}
                    />
                  </figure>

                  <div className="experince_poster_details custon_z_index">
                    <div
                      className={`d-flex justify-content-between ${
                        isMobile ? 'mb-2' : ''
                      }`}
                    >
                      <div className="responsive_font_movie_title">
                      Indulge in ultimate comfort in our Platinum Hall, featuring ultra-luxurious recliner seats. Sit back, relax, and enjoy state-of-the-art visuals and sound for an unparalleled cinematic journey. A top-tier movie experience to savor.{' '}
                      </div>
                    </div>
                    <div className="explore_wrapper mt-2">
                      <button
                        className="explore_btn"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          history.push('/PlatiniumExp');
                        }}
                      >
                        Explore
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>

          <Col xl={4} sm={12} md={4}>
              <div
                className="d-block parent_poster_wrapper"
                style={{ marginTop: isMobile ? '22px' : '' }}
              >
                <p className="question_title">Boutique Hall</p>
                <div
                  className={`${'movie_card_new custom_border_radius'} `}
                  // onMouseEnter={() => setIsShown(true)}
                  // onMouseLeave={() => setIsShown(false)}
                >
                  {/*has-tooltip previous class for on hover button*/}
                  {/* Image */}

                  <figure className="experience_img">
                    <img
                      src={boutiqhall1}
                      className="img-fluid"
                      alt={t('alt.Movie Poster')}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = dummyPosterStatic;
                      }}
                    />
                  </figure>
                  <div className="experince_poster_details custon_z_index">
                    <div
                      className={`d-flex justify-content-between ${
                        isMobile ? 'mb-2' : ''
                      }`}
                    >
                      <div className="responsive_font_movie_title">
                      Immerse yourself in an intimate and friendly cinema experience with our Boutique Hall, where every detail is designed to create a warm and exclusive atmosphere. Enjoy a unique concept that blends charm and modernity, perfect for smaller gatherings. The ideal spot to share meaningful moments in a welcoming setting{' '}
                      </div>
                    </div>
                    <div className="explore_wrapper mt-2">
                      <button
                        className="explore_btn"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          history.push('/Boutiquehall');
                        }}
                      >
                        Explore
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className='mt-4'>
          <Col xl={4} sm={12} md={4}>
              <div
                className="d-block parent_poster_wrapper"
                style={{ marginTop: isMobile ? '22px' : '' }}
              >
                <p className="question_title">Private Cinema</p>
                <div
                  className={`${'movie_card_new custom_border_radius'} `}
                  // onMouseEnter={() => setIsShown(true)}
                  // onMouseLeave={() => setIsShown(false)}
                >
                  {/*has-tooltip previous class for on hover button*/}
                  {/* Image */}

                  <figure className="experience_img">
                    <img
                      src={privatecinema}
                      className="img-fluid"
                      alt={t('alt.Movie Poster')}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = dummyPosterStatic;
                      }}
                      style={{height:'257.62px'}}
                    />
                  </figure>
                  <div className="experince_poster_details custon_z_index">
                    <div
                      className={`d-flex justify-content-between ${
                        isMobile ? 'mb-2' : ''
                      }`}
                    >
                      <div className="responsive_font_movie_title">
                      Rent our halls for your special events and provide your guests with a unique and memorable cinema experience. Whether for a birthday, a meeting, or an exclusive screening, our halls adapt to your needs. Make your important occasions unforgettable with Cinerji Cinemas.
                      </div>
                    </div>
                    <div className="explore_wrapper mt-2">
                      <button
                        className="explore_btn"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          history.push('/private-cinemas-cinerji');
                        }}
                      >
                        Explore
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={4} sm={12} md={4}>
              <div
                className="d-block parent_poster_wrapper"
                style={{ marginTop: isMobile ? '22px' : '' }}
              >
                <p className="question_title">Sport Lounge</p>
                <div
                  className={`${'movie_card_new custom_border_radius'} `}
                  // onMouseEnter={() => setIsShown(true)}
                  // onMouseLeave={() => setIsShown(false)}
                >
                  {/*has-tooltip previous class for on hover button*/}
                  {/* Image */}

                  <figure className="experience_img">
                    <img
                      src={sportroom}
                      className="img-fluid"
                      alt={t('alt.Movie Poster')}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = dummyPosterStatic;
                      }}
                      style={{height:'257.62px'}}
                    />
                  </figure>
                  <div className="experince_poster_details custon_z_index">
                    <div
                      className={`d-flex justify-content-between ${
                        isMobile ? 'mb-2' : ''
                      }`}
                    >
                      <div className="responsive_font_movie_title">
                      Watch your favorite sports events in a private and cozy setting in our Sport Lounge. Designed to host up to 10 people, this space offers a large screen and immersive sound, perfect for unforgettable moments with friends.
                      </div>
                    </div>
                    <div className="explore_wrapper mt-2">
                      <button
                        className="explore_btn"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          history.push('/live-sport-game-hall');
                        }}
                      >
                        Explore
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </section>
      </div>
    </>
  );
};
export default withNamespaces()(Experience);
